// Scripts from php templates from header.php
// TODO Need refactoring

// For using by legacy code after building with vite
window.header_slider = header_slider;
window.getSliderSettings940 = getSliderSettings940;
window.getSliderSettings480 = getSliderSettings480;

let index_series;
$(document).ready(function () {
  // переделка Оплаты и Доставки
  $('.header-links').append($('.header-links noindex a').clone());
  $('.header-links noindex a').addClass('displayNone');

  // На некоторых страницах скрываем бренды
  let arr = window.location.pathname;
  if ('/ordering' == arr || '/personal' == arr || '/cart' == arr) {
    $('.brands').addClass('brendsNone2');
    $('noindex').addClass('brendsNone');
    $('.slider_top_header').addClass('basket_brends');
    $('.others_brands').addClass('brendsNone');
  }

  //brends slider
  $('.brands_480').html($('.brands noindex').html());
  $('.brands_480 .brands_ul li').each(function () {
    $(this).removeClass('brands_li');
  });

  $('.slider_top_header .brands_ul').slick(getSliderSettings940());
  $('.brands_480 .brands_ul ').slick(getSliderSettings480());

  $('.brands .container .header-slider ul').append($('.brands-prod .brands-prod_li').clone());

  $('.brands .container .header-slider ul li').each(function () {
    $(this).removeClass('brands-prod_li');
  });

  index_series = $('.header-slider li.active').index();

  $('.header-slider ul')
    .slick(header_slider())
    .on('init', function () {});
});

function header_slider() {
  return {
    infinite: true,
    slidesToShow: 8,
    slidesToScroll: 8,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 7,
        },
      },
      {
        breakpoint: 868,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
}

function getSliderSettings940() {
  return {
    infinite: true,
    slidesToShow: 10,
    slidesToScroll: 10,
    responsive: [
      {
        breakpoint: 1149,
        settings: {
          slidesToShow: 9,
          slidesToScroll: 9,
        },
      },
      {
        breakpoint: 940,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 8,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 7,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
    ],
  };
}

function getSliderSettings480() {
  return {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
}

$(window).load(function () {
  if ($('.header-slider li.active').length > 0) {
    $('.header-slider ul').slick('slickGoTo', index_series, true);
  }
});
